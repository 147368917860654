



























































































































import { getDefaultForgotPasswordFormItems } from '@/services/AuthService/AuthService'
import { mapActions, mapGetters } from 'vuex'
import SignContainerComponent from '@/components/common/SignContainerComponent/SignContainerComponent.vue'
import { TextField } from '@/components/molecules/TextField'
import { EMAIL_REGEX_PATTERN } from '@/validation'
import { errorMessageRenderer } from '@/utils/utils'
import tracking from '@/tracking'

export default {
  name: 'SignIn',
  components: { SignContainerComponent, TextField },
  inject: ['enums'],
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      forgotPasswordFormItems: getDefaultForgotPasswordFormItems(),
      isPasswordForgotDialogShown: false,
      isMakeNewPasswordDialogShown: false,
      isLoadingForgotPassword: false,
      isLoadingMakeNewPassword: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      organisationStatus: 'getOrganisationStatus',
      user: 'getUser',
    }),
  },
  created(): void {
    const { email } = this.$route.query

    if (email) {
      this.form.email = email
    }
  },
  methods: {
    ...mapActions('auth', ['signIn', 'forgotPassword']),
    async onSignIn() {
      try {
        await this.$message.closeAll()
        await this.signIn({
          email: this.form.email.toLowerCase(),
          password: this.form.password,
        })
        if (this.organisationStatus === this.enums.OrganisationStatus.Draft) {
          this.$router.push({
            name: 'organisation',
            params: { id: this.user.organisationId },
          })
        }
      } catch (error: any) {
        tracking.trackError(error)
        throw new Error(errorMessageRenderer(error))
      }
    },
    onSignUp() {
      this.$router.push({ name: 'signUp' })
    },
    validateEmail(emailToAssess: string) {
      const isValid = EMAIL_REGEX_PATTERN.test(emailToAssess)
      return isValid ? true : this.$t('common.form.email.required')
    },
    async handleSubmitForgotPassword() {
      const email = this.form.email
      const isValidEmail = this.validateEmail(email) === true
      if (!isValidEmail) return
      try {
        this.isLoadingForgotPassword = true
        await this.forgotPassword({ email })
        this.forgotPasswordFormItems = getDefaultForgotPasswordFormItems()
        this.forgotPasswordFormItems.email = email
        this.isMakeNewPasswordDialogShown = true
      } catch (error: any) {
        throw new Error(errorMessageRenderer(error))
      } finally {
        this.isLoadingForgotPassword = false
      }
    },
    async handleSubmitMakeNewPassword() {
      this.isLoadingMakeNewPassword = true
      try {
        await this.forgotPassword(this.forgotPasswordFormItems)
        this.$message.success(this.$t(`login.message.confirm`))
        this.isMakeNewPasswordDialogShown = false
      } catch (error: any) {
        throw new Error(errorMessageRenderer(error))
      } finally {
        this.isLoadingMakeNewPassword = false
      }
    },
  },
}
