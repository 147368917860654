














import LandingHeaderComponent from '@/components/common/LandingHeaderComponent/LandingHeaderComponent.vue'
import FooterComponent from '@/components/common/FooterComponent/FooterComponent.vue'

export default {
  name: 'SignContainerComponent',
  components: { FooterComponent, LandingHeaderComponent },
  inject: ['enums'],
}
